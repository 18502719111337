<template>
  <router-view />
</template>

<script>
import { onMounted, onUnmounted, computed } from 'vue'
import { useStore } from 'vuex'
import '@/plugins/styles'
export default {
  name: 'App',
  setup() {
    const store = useStore()
    store.dispatch('setting/setSetting')
    const sidebarType = computed(() => store.getters['setting/sidebar_type'])
    const resizePlugin = () => {
      const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')
      if (window.innerWidth < 1025) {
        if (sidebarResponsive !== null) {
          if (!sidebarResponsive.classList.contains('sidebar-mini')) {
            sidebarResponsive.classList.add('on-resize')
            store.dispatch('setting/sidebar_type', [...sidebarType.value, 'sidebar-mini'])
          }
        }
      } else {
        if (sidebarResponsive !== null) {
          if (sidebarResponsive.classList.contains('sidebar-mini') && sidebarResponsive.classList.contains('on-resize')) {
            sidebarResponsive.classList.remove('on-resize')
            store.dispatch(
              'setting/sidebar_type',
              sidebarType.value.filter((item) => item !== 'sidebar-mini')
            )
          }
        }
      }
    }
    onMounted(() => {
      window.addEventListener('resize', resizePlugin)
      setTimeout(() => {
        resizePlugin()
      }, 200)
    })
    onUnmounted(() => {
      window.removeEventListener('resize', resizePlugin)
    })
  }
}
</script>

<style lang="scss">
@import '@/assets/custom-vue/scss/styles.scss';
</style>

<style>
.flatpickr-month .flatpickr-current-month .flatpickr-monthDropdown-months {
    font-size: 16px!important;
}
.flatpickr-month .flatpickr-current-month .numInputWrapper {
    font-size: 16px!important;
}

.flex-container {
    display: flex;
    flex-direction: row;
    /* gap: 20px;
  padding: 3vh 0; */
}


.flex-item-left {
    flex: 51%;
}

.flex-item-right {
    flex: 39%;
}

.flex-item-rightt {
    flex: 10%;
}

ul {
    list-style-type: none;
}

/* .wrapper2 {
  width: 600px;
} */
.dataTables_filter {
    float: right !important;
    padding-top: 10px;
    margin-bottom: 10px;
}

.dataTables_paginate {
    float: right !important;
}

.div2 {
    position: relative;
    overflow: hidden;
    /* border: 1px solid black; */
    white-space: nowrap;
}

@media (max-width: 800px) {
    .flex-container {
        flex-direction: column;
    }
}

</style>
