//const hostApi = process.env.NODE_ENV === "development" ? "https://be-simko-pasarjaya.cryper.id": process.env.NODE_ENV === "testing"? "http://103.108.131.78:3030" : "http://103.108.131.78:3010";
const hostApi = "https://be.simko-pj.mysurvey.id";
const portApi = process.env.NODE_ENV === "development" ? 3030 : "3010";
// const baseURLApi = `${hostApi}${portApi ? `` : ``}`;
const baseURLApi = `${hostApi}`;

//import loginData from "../pages/Login/Login"
 
export default {
  hostApi,
  portApi,
  baseURLApi,
}