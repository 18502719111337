import { createRouter, createWebHistory } from 'vue-router'

import appointmentRoutes from './modules-routes/appointment'
import blogRoutes from './modules-routes/blog'
import mailRoutes from './modules-routes/mail'
import chatRoutes from './modules-routes/chat'
import fileManagerRoutes from './modules-routes/file-manager'
import socialAppRoutes from './modules-routes/social-app'
import eCommerceRoutes from './modules-routes/e-commerce'
import store from "../store";

// Design System Routes
const designSystemChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.main',
    meta: { auth: true, name: 'Design System' },
    component: () => import('@/views/design-system/IndexPage.vue')
  }
]
// Auth Default Routes
const authChildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/default/SignIn.vue')
  },
  {
    path: 'register',
    name: prefix + '.register',
    meta: { auth: false, name: 'Register' },
    component: () => import('@/views/auth/default/SignUp.vue')
  },
  {
    path: 'reset-password',
    name: prefix + '.reset-password',
    meta: { auth: false, name: 'Reset Password' },
    component: () => import('@/views/auth/default/ResetPassword.vue')
  },
  {
    path: 'varify-email',
    name: prefix + '.varify-email',
    meta: { auth: false, name: 'Varify Email' },
    component: () => import('@/views/auth/default/VarifyEmail.vue')
  },
  {
    path: 'lock-screen',
    name: prefix + '.lock-screen',
    meta: { auth: false, name: 'Lock Screen' },
    component: () => import('@/views/auth/default/LockScreen.vue')
  }
]
// Auth Animated Routes
const authAnimatedChildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/animated/SignIn.vue')
  },
  {
    path: 'register',
    name: prefix + '.register',
    meta: { auth: false, name: 'Register' },
    component: () => import('@/views/auth/animated/SignUp.vue')
  },
  {
    path: 'reset-password',
    name: prefix + '.reset-password',
    meta: { auth: false, name: 'Reset Password' },
    component: () => import('@/views/auth/animated/ResetPassword.vue')
  },
  {
    path: 'account-deactivated',
    name: prefix + '.account-deactivated',
    meta: { auth: false, name: 'Account Deactivated' },
    component: () => import('@/views/auth/animated/AccountDeactivated.vue')
  },
  {
    path: 'varify-email',
    name: prefix + '.varify-email',
    meta: { auth: false, name: 'Varify Email' },
    component: () => import('@/views/auth/animated/VarifyEmail.vue')
  },
  {
    path: 'two-factor',
    name: prefix + '.two-factor',
    meta: { auth: false, name: 'Two Factor' },
    component: () => import('@/views/auth/animated/TwoFactor.vue')
  },
  {
    path: 'lock-screen',
    name: prefix + '.lock-screen',
    meta: { auth: false, name: 'Lock Screen' },
    component: () => import('@/views/auth/animated/LockScreen.vue')
  }
]

// Simple Auth Routes
const simpleAuthchildRoutes = (prefix) => [
  {
    path: 'login',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/simple/SignIn.vue')
  },
  {
    path: 'register',
    name: prefix + '.register',
    meta: { auth: false, name: 'Register' },
    component: () => import('@/views/auth/simple/SignUp.vue')
  }
]

// Plugins Routes
const pluginsChildRoutes = (prefix) => [
  {
    path: 'apexcharts',
    name: prefix + '.apexcharts',
    meta: { auth: true, name: 'Apexcharts', isBanner: true },
    component: () => import('@/views/plugins/ApexchartPage.vue')
  },
  {
    path: 'button-hover',
    name: prefix + '.button-hover',
    meta: { auth: true, name: 'Button Hover', isBanner: true },
    component: () => import('@/views/plugins/ButtonHoverPage.vue')
  },
  {
    path: 'flatpicker',
    name: prefix + '.flatpicker',
    meta: { auth: true, name: 'Flatpicker', isBanner: true },
    component: () => import('@/views/plugins/FlatpickerPage.vue')
  },
  {
    path: 'fslightbox',
    name: prefix + '.fslightbox',
    meta: { auth: true, name: 'Fslightbox', isBanner: true },
    component: () => import('@/views/plugins/FsLightBoxPage.vue')
  },
  {
    path: 'gallery-hover',
    name: prefix + '.gallery-hover',
    meta: { auth: true, name: 'Gallery Hover', isBanner: true },
    component: () => import('@/views/plugins/GalleryHover.vue')
  },
  {
    path: 'image-cropper',
    name: prefix + '.image-cropper',
    meta: { auth: true, name: 'Image Cropper', isBanner: true },
    component: () => import('@/views/plugins/ImageCropper.vue')
  },
  {
    path: 'loader',
    name: prefix + '.loader',
    meta: { auth: true, name: 'Loader', isBanner: true },
    component: () => import('@/views/plugins/LoaderPage.vue')
  },
  {
    path: 'multi-select',
    name: prefix + '.multi-select',
    meta: { auth: true, name: 'Multi Select', isBanner: true },
    component: () => import('@/views/plugins/MultiSelectPage.vue')
  },
  {
    path: 'quill',
    name: prefix + '.quill',
    meta: { auth: true, name: 'Quill', isBanner: true },
    component: () => import('@/views/plugins/QuillPage.vue')
  },
  {
    path: 'sweetalert',
    name: prefix + '.sweetalert',
    meta: { auth: true, name: 'Sweetalert', isBanner: true },
    component: () => import('@/views/plugins/SweetAlertPage.vue')
  },
  {
    path: 'tour',
    name: prefix + '.tour',
    meta: { auth: true, name: 'Tour', isBanner: true },
    component: () => import('@/views/plugins/TourPage.vue')
  },
  {
    path: 'uppy',
    name: prefix + '.uppy',
    meta: { auth: true, name: 'Uppy', isBanner: true },
    component: () => import('@/views/plugins/UppyPage.vue')
  }
]
// Dashboard routes
const dashboardRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.dashboard',
    meta: { auth: true, name: 'Home', isBanner: false },
    component: () => import('@/views/dashboards/IndexPage.vue')
  }
]
// Default routes
const defaultChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.dashboard',
    meta: { auth: true, name: 'Home', isBanner: false },
    component: () => import('@/views/dashboards/IndexPage.vue')
  },
  {
    path: '/pengaturan-matriks',
    name: prefix + '.pengaturan-matriks',
    meta: { auth: true, name: 'Pengaturan Matriks', isBanner: false },
    component: () => import('@/views/master/pengaturan-matriks/pengaturan-matriks.vue')
  },
  {
    path: '/unit-kerja',
    name: prefix + '.unit-kerja',
    meta: { auth: true, name: 'Cabang dan Unit Kerja', isBanner: false },
    component: () => import('@/views/master/cabang/index-cabang.vue')
  },
  {
    path: '/jenis-resiko',
    name: prefix + '.jenis-resiko',
    meta: { auth: true, name: 'Jenis Risiko', isBanner: false },
    component: () => import('@/views/master/jenis-resiko/jenis-resiko.vue')
  },
  
  //RPR
  {
    path: '/bisnis-proses',
    name: prefix + '.bisnis-proses',
    meta: { auth: true, name: 'Daftar Bisnis Proses Kegiatan', isBanner: false },
    component: () => import('@/views/rpr/bisnis-proses/daftarbisnisproses.vue')
  },
  {
    path: '/risiko',
    name: prefix + '.risiko',
    meta: { auth: true, name: 'Risiko', isBanner: false },
    component: () => import('@/views/rpr/penilaian-risiko/penilaian-risiko.vue')
  },
  {
    path: '/form-risiko',
    name: prefix + '.form-risiko',
    meta: { auth: true, name: 'Form Risiko', isBanner: false },
    component: () => import('@/views/rpr/penilaian-risiko/form-risiko.vue')
  },

  {
    path: '/lihat-risiko',
    name: prefix + '.lihat-risiko',
    meta: { auth: true, name: 'Risiko', isBanner: false },
    component: () => import('@/views/rpr/penilaian-risiko/lihat-risiko.vue')
  },

  {
    path: '/realisasi-mitigasi',
    name: prefix + '.realisasi-mitigasi',
    meta: { auth: true, name: 'Realisasi Mitigasi Risiko', isBanner: false },
    component: () => import('@/views/rpr/realisasi-mitigasi/realisasi-mitigasi.vue')
  },

  {
    path: '/realisasi-mitigasi/:id',
    name: prefix + '.form-realisasi-mitigasi',
    meta: { auth: true, name: 'Form Realisasi Mitigasi Risiko', isBanner: false },
    component: () => import('@/views/rpr/realisasi-mitigasi/form-realisasi-mitigasi.vue')
  },

  {
    path: '/pemantauan-risiko',
    name: prefix + '.pemantauan-risiko',
    meta: { auth: true, name: 'Pemantauan Risiko', isBanner: false },
    component: () => import('@/views/rpr/pemantauan-risiko/pemantauan-risiko.vue')
  },
  {
    path: '/loss-event',
    name: prefix + '.loss-event',
    meta: { auth: true, name: 'Lost Event', isBanner: false },
    component: () => import('@/views/rpr/loss-event/loss-event.vue')
  },
  {
    path: '/tambah-asesmenperlakuan',
    name: prefix + '.tambah-asesmenperlakuan',
    meta: { auth: true, name: 'Form Asesmen & Perlakuan', isBanner: false },
    component: () => import('@/views/rpr/risiko/tambahrpr.vue')
  },
  {
    path: '/lihat-asesmenperlakuan',
    name: prefix + '.lihat-asesmenperlakuan',
    meta: { auth: true, name: 'Lihat Asesmen & Perlakuan', isBanner: false },
    component: () => import('@/views/rpr/risiko/lihatasesmenperlakuan.vue')
  },

  //Master data
  {
    path: '/kategori-dampak',
    name: prefix + '.kategori-dampak',
    meta: { auth: true, name: 'Kategori Dampak', isBanner: false },
    component: () => import('@/views/master/dampak/kategori-dampak/Index-kategori.vue')
  },
  {
    path: '/kriteria-dampak',
    name: prefix + '.kriteria-dampak',
    meta: { auth: true, name: 'Kriteria Dampak', isBanner: false },
    component: () => import('@/views/master/dampak/kriteria-dampak/index-kriteria.vue')
  },
  {
    path: '/indikator-dampak',
    name: prefix + '.indikator-dampak',
    meta: { auth: true, name: 'Indikator Dampak', isBanner: false },
    component: () => import('@/views/master/dampak/indikator-dampak/Index-indikator.vue')
  },
  
  {
    path: '/users-data',
    name: prefix + '.users-data',
    meta: { auth: true, name: 'Pengguna', isBanner: false },
    component: () => import('@/views/master/users/index-users.vue')
  },
  {
    path: '/kategori-pertimbangan',
    name: prefix + '.kategori-pertimbangan',
    meta: { auth: true, name: 'Kategori Pertimbangan', isBanner: false },
    component: () => import('@/views/master/kategori-pertimbangan/kategori-pertimbangan-index.vue')
  },
  {
    path: '/kategori-resiko',
    name: prefix + '.kategori-resiko',
    meta: { auth: true, name: 'Kategori Risiko', isBanner: false },
    component: () => import('@/views/master/kategori-resiko/kategori-resiko-index.vue')
  },
  {
    path: '/parameter-risiko',
    name: prefix + '.parameter-risiko',
    meta: { auth: true, name: 'Parameter Risiko', isBanner: false },
    component: () => import('@/views/master/parameter-risiko/parameter-risiko.vue')
  },
  {
    path: '/probabilitas',
    name: prefix + '.probabilitas',
    meta: { auth: true, name: 'Kemungkinan', isBanner: false },
    component: () => import('@/views/master/probabilitas/probabilitas-index.vue')
  },
  {
    path: '/cba',
    name: prefix + '.cba',
    meta: { auth: true, name: 'CBA', isBanner: false },
    component: () => import('@/views/report/cba/cba-index.vue')
  },
  {
    path: '/final-resiko',
    name: prefix + '.final-resiko',
    meta: { auth: true, name: 'Final Risiko', isBanner: false },
    component: () => import('@/views/report/final-resiko/final-resiko-index.vue')
  },
  {
    path: '/monitoring-resiko',
    name: prefix + '.monitoring-resiko',
    meta: { auth: true, name: 'Monitoring Risiko', isBanner: false },
    component: () => import('@/views/report/monitor-resiko/monitor-resiko-index.vue')
  },
  {
    path: '/prioritas-resiko',
    name: prefix + '.prioritas-resiko',
    meta: { auth: true, name: 'Prioritas Risiko', isBanner: false },
    component: () => import('@/views/report/prioritas/prioritas.vue')
  },
  {
    path: '/tidak-turun-risiko',
    name: prefix + '.tidak-turun-risiko',
    meta: { auth: true, name: 'Risiko Tidak Turun', isBanner: false },
    component: () => import('@/views/report/tidak-turun/tidak-turun.vue')
  },
  
  {
    path: '/monitoring',
    name: prefix + '.monitoring',
    meta: { auth: true, name: 'Daftar Monitoring', isBanner: false },
    component: () => import('@/views/rpr/monitoring/daftar.vue')
  },
  {
    path: '/detail-monitoring/:id',
    name: prefix + '.detail-monitoring',
    meta: { auth: true, name: 'Detail Monitoring', isBanner: false },
    component: () => import('@/views/rpr/monitoring/monitoring.vue')
  },
  {
    path: '/smart-assisten',
    name: prefix + '.smart-assisten',
    meta: { auth: true, name: 'Smart Asisten', isBanner: false },
    component: () => import('@/views/master/smart-asisten/smart-index.vue')
  },
  {
    path: '/data-histori',
    name: prefix + '.data-histori',
    meta: { auth: true, name: 'Data Histori', isBanner: false },
    component: () => import('@/views/data-histori/histori-index.vue')
  },
  {
    path: '/log',
    name: prefix + '.log',
    meta: { auth: true, name: 'Log', isBanner: false },
    component: () => import('@/views/log/log.vue')
  },
  {
    path: '/panduan',
    name: prefix + '.panduan',
    meta: { auth: true, name: 'Panduan', isBanner: false },
    component: () => import('@/views/setting-panduan/panduan-index.vue')
  },
  {
    path: '/buku-panduan',
    name: prefix + '.buku-panduan',
    meta: { auth: true, name: 'Buku Panduan', isBanner: false },
    component: () => import('@/views/setting-panduan/buku-panduan.vue')
  },
  {
    path: '/video-tutorial',
    name: prefix + '.video-tutorial',
    meta: { auth: true, name: 'Buku Panduan', isBanner: false },
    component: () => import('@/views/setting-panduan/video-tutorial.vue')
  },
  {
    path: '/prosedur',
    name: prefix + '.prosedur',
    meta: { auth: true, name: 'Prosedur', isBanner: false },
    component: () => import('@/views/setting-panduan/prosedur-index.vue')
  },
  {
    path: '/panduan-lainnya',
    name: prefix + '.panduan-lainnya',
    meta: { auth: true, name: 'Panduan Lainnya', isBanner: false },
    component: () => import('@/views/setting-panduan/panduan-lainnya-index.vue')
  },
  {
    path: '/viewPanduan/:id',
    name: prefix + '.viewPanduan',
    meta: { auth: true, name: 'Lihat Panduan', isBanner: false },
    component: () => import('@/views/setting-panduan/viewer-panduan.vue')
  },
  {
    path: '/lihatpanduan/:panduan',
    name: prefix + '.lihatpanduan',
    meta: { auth: true, name: 'Lihat Panduan', isBanner: false },
    component: () => import('@/views/setting-panduan/lihat-panduan.vue')
  },
  {
    path: '/kpi',
    name: prefix + '.kpi',
    meta: { auth: true, name: 'KPI', isBanner: false },
    component: () => import('@/views/master/kpi/kpi-index.vue')
  },
  {
    path: '/mapping',
    name: prefix + '.mapping',
    meta: { auth: true, name: 'Alur Maping', isBanner: false },
    component: () => import('@/views/master/mapping/mapping-index.vue')
  },
  {
    path: '/ubahpassword',
    name: prefix + '.ubah_password',
    meta: { auth: true, name: 'Ubah Password', isBanner: false },
    component: () => import('@/views/setting-users/setting-user.vue')
  },
  {
    path: '/alternate-dashboard',
    name: prefix + '.alternate-dashboard',
    meta: { auth: true, name: 'Alternate Dashboard', isBanner: false },
    component: () => import('@/views/dashboards/AlternateDashboard.vue')
  },
  {
    path: '/blank-page',
    name: prefix + '.blank-page',
    meta: { auth: true, name: 'Blank Page', isBanner: true },
    component: () => import('@/views/BlankPage.vue')
  },
  // Spacial Pages
  {
    path: '/billing',
    name: 'special-pages.billing',
    meta: { auth: true, name: 'Billing', isBanner: true },
    component: () => import('@/views/spacial-pages/BillingPage.vue')
  },
  {
    path: '/calender',
    name: 'special-pages.calender',
    meta: { auth: true, name: 'Calender', isBanner: true },
    component: () => import('@/views/spacial-pages/CalenderPage.vue')
  },
  {
    path: '/kanban',
    name: 'special-pages.kanban',
    meta: { auth: true, name: 'Kanban', isBanner: true },
    component: () => import('@/views/spacial-pages/KanbanPage.vue')
  },
  {
    path: '/pricing',
    name: 'special-pages.pricing',
    meta: { auth: true, name: 'Pricing', isBanner: true },
    component: () => import('@/views/spacial-pages/PricingPage.vue')
  },
  {
    path: '/timeline',
    name: 'special-pages.timeline',
    meta: { auth: true, name: 'Timeline', isBanner: true },
    component: () => import('@/views/spacial-pages/TimelinePage.vue')
  },
  // Users Pages
  {
    path: '/user-list',
    name: 'user.user-list',
    meta: { auth: true, name: 'User List', isBanner: true },
    component: () => import('@/views/user/ListPage.vue')
  },
  {
    path: '/user-add',
    name: 'user.user-add',
    meta: { auth: true, name: 'User Add', isBanner: true },
    component: () => import('@/views/user/AddPage.vue')
  },
  {
    path: '/user-profile',
    name: 'user.user-profile',
    meta: { auth: true, name: 'User Add', isBanner: true },
    component: () => import('@/views/user/ProfilePage.vue')
  },
  {
    path: '/privacy-setting',
    name: 'user.user-privacy-setting',
    meta: { auth: true, name: 'Privacy Setting', isBanner: true },
    component: () => import('@/views/user/PrivacySetting.vue')
  },
  // Widgets Pages
  {
    path: '/widget-basic',
    name: 'widget.widget-basic',
    meta: { auth: true, name: 'Widget Basic', isBanner: true },
    component: () => import('@/views/widgets/WidgetBasic.vue')
  },
  {
    path: '/widget-chart',
    name: 'widget.widget-chart',
    meta: { auth: true, name: 'Widget Chart', isBanner: true },
    component: () => import('@/views/widgets/WidgetChart.vue')
  },
  {
    path: '/widget-card',
    name: 'widget.widget-card',
    meta: { auth: true, name: 'Widget Card', isBanner: true },
    component: () => import('@/views/widgets/WidgetCard.vue')
  },
  // Map Pages
  {
    path: '/map-google',
    name: 'maps.map-google',
    meta: { auth: true, name: 'Google Map', isBanner: true },
    component: () => import('@/views/maps/GooglePage.vue')
  },
  // Form Pages
  {
    path: '/elements',
    name: 'form.elements',
    meta: { auth: true, name: 'Elements', isBanner: true },
    component: () => import('@/views/forms/ElementsPage.vue')
  },
  {
    path: '/validation',
    name: 'form.validation',
    meta: { auth: true, name: 'Validation', isBanner: true },
    component: () => import('@/views/forms/ValidationPage.vue')
  },
  {
    path: '/wizard',
    name: 'form.wizard',
    meta: { auth: true, name: 'Wizard', isBanner: true },
    component: () => import('@/views/forms/WizardPage.vue')
  },
  // Table Pages
  {
    path: '/bootstrap-table',
    name: 'table.bootstrap-table',
    meta: { auth: true, name: 'Botstrap Table', isBanner: true },
    component: () => import('@/views/tables/BootstrapTable.vue')
  },
  {
    path: '/border-table',
    name: 'table.border-table',
    meta: { auth: true, name: 'Border Table', isBanner: true },
    component: () => import('@/views/tables/BorderTable.vue')
  },
  {
    path: '/fancy-table',
    name: 'table.fancy-table',
    meta: { auth: true, name: 'Fancy Table', isBanner: true },
    component: () => import('@/views/tables/FancyTable.vue')
  },
  {
    path: '/fixed-table',
    name: 'table.fixed-table',
    meta: { auth: true, name: 'FixedTable Table', isBanner: true },
    component: () => import('@/views/tables/FixedTable.vue')
  },
  // Icons Pages
  {
    path: '/icons/solid',
    name: 'icons.solid',
    meta: { auth: true, name: 'Solid Icon', isBanner: true },
    component: () => import('@/views/icons/SolidIcon.vue')
  },
  {
    path: '/icons/outlined',
    name: 'icons.outlined',
    meta: { auth: true, name: 'Outlined Icon', isBanner: true },
    component: () => import('@/views/icons/OutlinedIcon.vue')
  },
  {
    path: '/icons/dual-tone',
    name: 'icons.dual-tone',
    meta: { auth: true, name: 'Dual Tone Icon', isBanner: true },
    component: () => import('@/views/icons/DualToneIcon.vue')
  },
  // Extra Pages
  {
    path: '/privacy-policy',
    name: prefix + '.privacy-policy',
    meta: { auth: true, name: 'Privacy Policy', isBanner: true },
    component: () => import('@/views/extra/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: prefix + '.terms-and-conditions',
    meta: { auth: true, name: 'Terms and Conditions', isBanner: true },
    component: () => import('@/views/extra/TermsAndConditions.vue')
  },
  {
    path: '/ui-color',
    name: prefix + '.ui-color',
    meta: { auth: true, name: 'UI Color', isBanner: true },
    component: () => import('@/views/extra/UiColorPage.vue')
  },
  {
    path: '/admin-permissions',
    name: prefix + '.admin-permissions',
    meta: { auth: true, name: 'Admin Permissions', isBanner: true },
    component: () => import('@/views/admin/AdminPage.vue')
  }
]

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { auth: true, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { auth: true, name: 'Error 500', isBanner: true },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { auth: true, name: 'Maintenance', isBanner: true },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false, name: 'Login' },
    component: () => import('@/views/auth/simple/SignIn.vue')
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('default')
  },
  {
    path: '/pengaturan-matriks',
    name: 'pengaturan-matriks',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('pengaturan-matriks')
  },
  {
    path: '/unit-kerja',
    name: 'unit-kerja',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('unit-kerja')
  },
  {
    path: '/jenis-resiko',
    name: 'jenis-resiko',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('jenis-resiko')
  },
  {
    path: '/design-system',
    name: 'design-system',
    meta: {requiresAuth: true},
    component: () => import('../layouts/DefaultLayout.vue'),
    children: designSystemChildRoutes('design-system')
  },
  {
    path: '/rpr',
    name: 'rpr',
    meta: {requiresAuth: true},
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('rpr')
  },
  {
    path: '/indikator-dampak',
    name: 'indikator-dampak',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('indikator-dampak')
  },
  {
    path: '/kategori-dampak',
    name: 'kategori-dampak',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('kategori-dampak')
  },
  {
    path: '/kriteria-dampak',
    name: 'kriteria-dampak',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('kriteria-dampak')
  },
  {
    path: '/probailitas',
    name: 'probailitas',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('probailitas')
  },
  {
    path: '/users-data',
    name: 'users-data',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('users-data')
  },
  {
    path: '/kategori-pertimbangan',
    name: 'kategori-pertimbangan',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('kategori-pertimbangan')
  },
  {
    path: '/kategori-resiko',
    name: 'kategori-resiko',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('kategori-resiko')
  },
  {
    path: '/cba',
    name: 'cba',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('cba')
  },
  {
    path: '/final-resiko',
    name: 'final-resiko',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('final-resiko')
  },
  {
    path: '/monitoring-resiko',
    name: 'monitoring-resiko',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('monitoring-resiko')
  },
  {
    path: '/smart-assisten',
    name: 'smart-assisten',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('smart-assisten')
  },
  {
    path: '/data-histori',
    name: 'data-histori',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('data-histori')
  },
  {
    path: '/panduan',
    name: 'panduan',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('panduan')
  },
  {
    path: '/buku-panduan',
    name: 'buku-panduan',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('buku-panduan')
  },
  {
    path: '/video-tutorial',
    name: 'video-tutorial',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('video-tutorial')
  },
  {
    path: '/prosedur',
    name: 'prosedur',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('prosedur')
  },
  {
    path: '/panduan_lainnya',
    name: 'panduan_lainnya',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('panduan_lainnya')
  },
  {
    path: '/viewPanduan/:id',
    name: 'viewPanduan',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('viewPanduan')
  },
  {
    path: '/lihatpanduan/:panduan',
    name: 'lihatpanduan',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('lihatpanduan')
  },
  {
    path: '/kpi',
    name: 'kpi',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('kpi')
  },
  {
    path: '/mapping',
    name: 'mapping',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('mapping')
  },
  {
    path: '/ubahpassword',
    name: 'ubah_password',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('ubah_password')
  },
  // Default Pages
  // {
  //   path: '/dashboard',
  //   name: 'dashboard',
  //   component: () => import('../layouts/DefaultLayout.vue'),
  //   children: defaultChildRoutes('default')
  // },
  // Menu Styles
  {
    path: '/horizontal',
    name: 'horizontal',
    component: () => import('../layouts/menu-styles/HorizontalLayout.vue'),
    children: dashboardRoutes('horizontal')
  },
  {
    path: '/dual-horizontal',
    name: 'dual-horizontal',
    component: () => import('../layouts/menu-styles/DualHorizontalLayout.vue'),
    children: dashboardRoutes('dual-horizontal')
  },
  {
    path: '/dual-compact',
    name: 'dual-compact',
    component: () => import('../layouts/menu-styles/DualCompactLayout.vue'),
    children: dashboardRoutes('dual-compact')
  },
  {
    path: '/boxed',
    name: 'boxed',
    component: () => import('../layouts/menu-styles/BoxedLayout.vue'),
    children: dashboardRoutes('boxed')
  },
  {
    path: '/boxed-fancy',
    name: 'boxed-fancy',
    component: () => import('../layouts/menu-styles/BoxedFancyLayout.vue'),
    children: dashboardRoutes('boxed-fancy')
  },

  // Auth Skins
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: authChildRoutes('auth')
  },
  {
    path: '/animated-auth',
    name: 'animated-auth',
    component: () => import('../layouts/guest/AuthAnimated.vue'),
    children: authAnimatedChildRoutes('animated.auth')
  },
  {
    path: '/simple-auth',
    name: 'simple-auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: simpleAuthchildRoutes('simple.auth')
  },
  // Plugins
  {
    path: '/plugins',
    name: 'plugins',
    component: () => import('../layouts/DefaultLayout'),
    children: pluginsChildRoutes('plugins')
  },
  // Errors Pages
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: errorRoutes('errors')
  },
  // Modules
  ...appointmentRoutes,
  ...blogRoutes,
  ...mailRoutes,
  ...chatRoutes,
  ...fileManagerRoutes,
  ...socialAppRoutes,
  ...eCommerceRoutes
]

const router = createRouter({
  store,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && localStorage.getItem('token') == null) {
    next({ name: 'login' })
  }
  else if(to.name == 'login' && localStorage.getItem('token') != null){
    next({ name: 'dashboard' })
  }else{
    next()
  }
})

export default router
